import PropTypes from "prop-types"
import React from "react"
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col'
import Image from "gatsby-image"
import Marquee from 'react-text-marquee';

const Header = ({ siteTitle, imgBg, imgLogo, imgBgAlt, imgLogoAlt }) => (
  <header id={siteTitle}>
    <Container fluid className="textHeaderScreen" >
      <Row noGutters className="align-middle">
        <Col md={1} className="headerIcon"></Col>
        <Col md={3} sm={6} xs={12} className="headerName"><h2 className="text-uppercase"><Marquee text={siteTitle}/></h2></Col>
        <Col md={4} sm={6}>
          <Image className="headerLogo"
            alt={imgLogoAlt}
            fluid={imgLogo}
            />
        </Col>
        <Col md={4} className="headerSpacer"></Col>
      </Row>
    </Container>
    <Container fluid className="screenHeader no-padding">
      <Row noGutters>
        <Col  xl={4} lg={4} md={4} sm={6} xs={12} className="headerBackgr">
          <Image className="headerBg"
            alt={imgBgAlt}
            fluid={imgBg}
            />
        </Col>
      </Row>
    </Container>
  </header>
)

Header.propTypes = {
  siteTitle: PropTypes.string,
  imgBg: PropTypes.object,
  imgLogo: PropTypes.object,
  imgBgAlt: PropTypes.string,
  imgLogoAlt: PropTypes.string
}

Header.defaultProps = {
  siteTitle: ``,
  imgBg: ``,
  imgLogo:``,
  imgBgAlt:`pic`,
  imgLogoAlt:`logo`
}

export default Header
